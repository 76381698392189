<template>
  <div>
    <v-card elevation="0" class="pa-0 pb-6 pt-6">
      <v-dialog v-model="loaders.sortimage">

        <v-card>
          <v-card-text>Déplacer les images de façon verticale pour les réorganiser.</v-card-text>
          <v-list class="mb-3" style="max-height: 350px; overflow: auto"
                  v-if="filestoupload.length"
                  dense
          >
            <draggable
              v-model="filestoupload"
              handle=".handle"
            >
              <transition-group name="list">
                <template v-for="(item, $index) in filestoupload">
                  <v-list-item
                    :key="'img-'+$index"
                    class=" handle bordered"
                    dense
                  >
                    <img
                      class="mr-3"
                      width="50"
                      :src="item.oburl"
                    >

                    <v-list-item-content>
                      <v-list-item-title class="title-inline">
                        {{ item.file.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ (item.file.size / 1024 / 1024).toFixed(2) }} Mo
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </transition-group>
            </draggable>
          </v-list>

          <v-card-actions class=" ">

            <v-btn color="secondary" @click="loaders.sortimage = false">Annuler</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="loaders.sortimage = false">Enregistrer cet ordre</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <template>

        <v-card elevation="0" max-height="800px">

          <v-img v-if="mainpost"
                 :src="mainpost.postimages[0].uploaddir+'700_'+mainpost.postimages[0].postimage"
                 class="align-top mb-3"
                 gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                 height="120px"
                 cover
          >
            <v-card-title class="text-white">
              {{ mainpost.title }}
              <v-spacer></v-spacer>
              <v-btn color="primary"
                     fab small
                     @click="$destroy; $emit('close'); "
              >
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-card-title>
          </v-img>

          <v-btn
            v-if="filestoupload.length > 1 && loaders.postavailable" small class="ma-3"
            @click="loaders.sortimage = true">
            <v-icon>{{ icons.mdiArrowUpDown }}</v-icon>
            Modifier l'ordre des images
          </v-btn>
          <v-list class="mb-3" style="max-height: 350px; overflow: auto"
                  v-if="filestoupload.length"
                  dense
          >
            <template v-for="(item, $index) in filestoupload">
              <post-image-form-controller
                :image="item"
                :key="item.oburl"
                :post="post"
                :index="$index"
                @removeimage="removeimage"
                @uploaded="uploaded"
              ></post-image-form-controller>
            </template>
          </v-list>
          <v-card-text hidden class="d-none">
            <v-file-input id="fileInput"
                          v-model="images"
                          label="Ajouter une image"
                          dense
                          multiple
                          accept=".jpeg, .jpg, .png, .gif"
                          outlined
                          @change="addimages(images)"
                          @click="savesample(null, 'create.post')"
            ></v-file-input>
          </v-card-text>

        </v-card>

      </template>

      <v-row no-gutters  class="align-baseline">
        <v-col>
          <v-textarea
            v-if="post.id"
            v-model="post.content"
            auto-grow
            rows="1"
            outlined dense
            :prepend-icon="icons.mdiImage"
            clearable
            @click:prepend="document.getElementById('fileInput').click()"
            :label="e1 === 5? 'Description' : 'Quoi de neuf? ...'"
          ></v-textarea>
          <v-textarea
            v-else
            v-model="postcontent"
            auto-grow dense
            rows="1"
            outlined
            :prepend-icon="icons.mdiImage"
            clearable
            :label="e1 === 5? 'Description' : 'Quoi de neuf? ...'"
            @click:prepend="document.getElementById('fileInput').click()"
          ></v-textarea>
        </v-col>
        <v-col cols="2" class="justify-center text-center">
          <v-btn absolute style="bottom: 36px; right: 30px"
                 :disabled="!loaders.postavailable"
                 color="primary" icon small
                 @click="publish"
          >
            <v-icon>{{ icons.mdiSend }}</v-icon>
          </v-btn>
          <v-btn absolute style="bottom: 90px; right: 18px"
                 icon small fab elevation="2" color="default"
                 @click="$emit('close')"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!--      <v-card-text style="position: relative;" v-if="e1 != 1" class="pb-0 mb-0 flex">

            </v-card-text>-->

    </v-card>

    <v-dialog
      v-model="loaders.post"
      persistent
      width="300"
    >
      <v-card
        color="secondary"
      >
        <v-card-text>
          Publication en cours ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiSend,
  mdiImage,
  mdiFolderAlert,
  mdiClose,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAccountGroupOutline,
  mdiChevronLeft,
  mdiArrowUpDown,
  mdiFileImageOutline
} from '@mdi/js'
import {
  computed, ref, onMounted, onUnmounted, watch,
} from '@vue/composition-api'
import draggable from 'vuedraggable'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import PostImageFormController from '@/components/PostImageFormController.vue'

export default {
  components: {
    PostImageFormController,
    draggable,
  },
  props: {
    postcategories: Array,
    mainpost: {
      type: Object,
      default: () => {
      },
    },
    postedit: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props, {emit}) {
    const $vuetify = getVuetify()
    const user = Drequest.getUser()
    const items = ref([])
    const filestoupload = ref([])
    const files = ref([])
    const images = ref([])
    const nbimageuploaded = ref(0)
    const nbimages = ref(0)
    const e1 = ref(3)

    // const post = ref({})
    const loaders = ref({
      sortimage: false,
      post: false,
      identify: false,
      postavailable: false,
    })
    const snackbar = ref({})
    const hashtags = ref([])
    const identifies = ref([])
    const userreference = ref({})
    const postcontent = ref('')

    const post = computed(() => props.postedit)
    watch(post, (post, prevPost) => {
      console.log(post, prevPost)
      if (post.id) {
        if (post.category) {
          // e1.value = post.category.slug - 2
        }

        // if(post.content)
        //     filterIdentify(post.content)
      } else {
        post.statuskey = 'published'
      }
    })

    watch(postcontent, (postcontent, prevPostcontent) => {
      console.log(postcontent, prevPostcontent)
      // if (postcontent){
      //   filterIdentify(postcontent)
      // }

    })
    // myString.match(/(^|\s)([#@][a-z\d-]+)/ig);
    //postcontent
    const closecomponent = () => {

    }
    const addimage = () => {

    }

    let usernamefilter = '',
      lastids = 0,
      identifications = {}

    const filterIdentify = (postcontent) => {

      hashtags.value = postcontent.match(/(^|\s)([#][a-z\d-]+)/ig)
      identifies.value = postcontent.match(/(^|\s)([@][a-z\d-]+)/ig)
      if (identifies.value) {
        usernamefilter = identifies.value[identifies.value.length - 1].replace('@', '')
          .replace(' ', '')
        console.log(usernamefilter)
        if (usernamefilter.length < 3) {
          lastids = 0
        }
        if (usernamefilter.length > 3 && identifies.value.length > lastids) {
          lastids = identifies.value.length
          loaders.value.identify = true
          Drequest.api(`lazyloading.user?dfilters=on&id:neq=${user.id}&username:op=` + usernamefilter)
            .get(response => {
              console.log(response)
              loaders.value.identify = false
              userreference.value = response
            })
        }
      }
    }
    const confirmref = (user) => {
      let replacestr = JSON.parse(JSON.stringify(postcontent.value))
      console.log(replacestr, replacestr.replace(`@${usernamefilter}`, '@' + user.username + ' '))
      if (postcontent.value) {
        postcontent.value = replacestr.replace(`@${usernamefilter}`, '@' + user.username + ' ')

      } else {
        replacestr = JSON.parse(JSON.stringify(post.value.content))
        post.value.content = replacestr.replace(`@${usernamefilter}`, '@' + user.username + ' ')

      }

      identifications[user.username] = user.id
      console.log(identifications)
      userreference.value.listEntity = null
    }

    const addimages = files => {
      console.log(files)
      /* if(!this.post.id){
        alert("Oops une erreur est survenue lors du chargement de vos images. Selectionnez de nouveau svp. si le problème persiste, actualiser la page, votre publication sera conservée.");
        return 0;
      } */
      // files.value = ev.target.files
      loaders.value.postavailable = false
      if (files[0]) {
        for (let i = 0; i < files.length; i++) {
          files[i].toupload = true
          filestoupload.value.push({
            file: files[i],
            oburl: window.URL.createObjectURL(files[i]),
          })
        }
      } else if (files.name) {
        files.toupload = true
        filestoupload.value.push({
          file: files,
          oburl: window.URL.createObjectURL(files),
        })
      }
      if (filestoupload.value.length > 1 && e1.value == 2) {
        e1.value = 3
      }

      console.log(filestoupload.value)
      nbimages.value = filestoupload.value.length
    }

    const uploaded = () => {
      // images.value = []
      nbimageuploaded.value = 0
      for (let item of filestoupload.value)
        if (item.file.id)
          nbimageuploaded.value++

      loaders.value.postavailable = false
      console.log(filestoupload.value, nbimageuploaded.value, nbimages.value)
      if (nbimageuploaded.value === filestoupload.value.length) {
        loaders.value.postavailable = true
        //this.callbackpersistence(this.serverresponse);
        console.log('all images hava well been uploaded thx')
      }
    }
    watch(images, (images, previmages) => {
      // console.log(postcontent, prevPostcontent)
      uploaded()
    })
    const removeimage = (file, index) => {
      filestoupload.value.splice(index, 1)
      nbimages.value--

      if (file.id) {
        nbimageuploaded.value--
        Drequest.api('postimage.delete?id=' + file.id)
          .get((response) => {
            console.log(response)
          })
      }
      /* if (nbimageuploaded.value === nbimages.value) {
        loaders.value.postavailable = true
      } */
      uploaded()

    }

    const urlify = text => {
      const urlRegex = /(https?:\/\/[^\s]+)/g

      return text.replace(urlRegex, url => `<a href="${url}">${url}</a>`)

      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }

    const close = () => {
      if (!confirm('Confirmer l\'annulation de cette publication?')) {
        return 0
      }

      filestoupload.value = []
      postcontent.value = ''
      images.value = []
      nbimages.value = 0
      e1.value = 1
      //destroy()
      emit('close')
    }

    const savesample = (el, url) => {

      if (post.value.id) {
        uploaded()
        return null
      }

      Drequest.api(url)
        .data(
          {
            post: {
              'user.id': user.id,
              'title': post.value.title,
              statuskey: 'draft',
              'tree_item\\category.id': post.value.category.id,
              'content': post.value.content //$("#postcontent").html(),
            }
          })
        .raw((response) => {

          if (response.success) {
            post.value.id = response.post.id
            //this.db.post = this.post;
            //Drequest.localsave(this.db)
          }

        })

    }
    const publish = () => {

      if (post.value.id) {
        loaders.value.post = true

        let positions = []
        for (let item of filestoupload.value)
          if (item.file.id)
            positions.push(item.file.id)

        Drequest.api(`post.update?id=${post.value.id}`)
          .data({
            'identifications': '', //identifications,
            'identificationnames': '', //identifies.value.join(),
            'positions': positions.join(","), //identifies.value.join(),
            post: {
              title: post.value.title,
              content: post.value.content,
              statuskey: post.value.statuskey,
              //'hashtag' : hashtags.value ?? hashtags.value.join(),
            },
          })
          .raw(response => {
            console.log(response)
            loaders.value.post = false
            filestoupload.value = []
            postcontent.value = ''
            post.value.title = ''
            post.value.content = ''
            post.value.id = ''
            images.value = []
            nbimages.value = 0
            e1.value = 3
            emit('posted', response.post)
          })
          .fail(e => {
            loaders.value.post = false
            alert('Oops Il y a eu un probleme de connexion essayer de nouveau SVP !')
            console.log(e)
          })

        return 0
      }
      if (!nbimages.value) {
        window.alert('Vous devez inserer au moins une image.')

        return 0
      }

    }

    return {
      confirmref,
      addimages,
      publish,
      removeimage,
      close,
      savesample,
      uploaded,

      hashtags,
      identifies,
      items,
      post,
      loaders,
      postcontent,
      images,
      nbimages,
      filestoupload,
      e1,
      userreference,

      document: document,
      icons: {
        mdiClose,
        mdiFolderAlert,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFileImageOutline,
        mdiAccountGroupOutline,
        mdiArrowUpDown,
        mdiChevronLeft,
        mdiImage,
        mdiSend
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.todo {
  border-top: 1px solid var(--v-secondary-base);
}

.list-move {
  transition: .3s;
}
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer{
  position: absolute;
  bottom: 3px;
  left: 6px;
}.v-text-field.v-input--dense {
   padding-left: 33px;
 }
.v-text-field fieldset, .v-text-field .v-input__control {
  margin-left: 9px;
}
</style>
