<template>
<span>

                <v-btn v-if="follow" @click="toggleFollow"
                       size="x-small" text x-small
                       :loading="loader.follow"
                >
                  Se desabonner
                </v-btn>
                <v-btn v-else @click="toggleFollow"
                       variant="outlined"
                       size="x-small"  x-small
                       :loading="loader.follow"
                >
                  S'abonner
                </v-btn>
<!--                <v-btn
                  variant="outlined"
                  size="x-small"
                >
                  <v-icon>{{ icons.mdiDotsHorizontal }}</v-icon>
                </v-btn>-->

</span>
</template>

<script>
import { Drequest } from '@/plugins/Drequest'
import { computed, ref } from '@vue/composition-api'
import {
  mdiComment,
  mdiDotsVertical, mdiEyeOffOutline,
  mdiEyeOutline,
  mdiHeart,
  mdiHeartOutline,
  mdiShareVariant
} from '@mdi/js'

export default {
  name: 'FollowToggleComponent',
  props: {
    'status': Number,
    user_id: {
      type: Number,
      default: () => null,
    },
    current_user_id: {
      type: Number,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    let follow = computed(() => props.status)

    const loader = ref({
      loading: true,
      follow: false,
    })
    const toggleFollow = () => {
      if (props.current_user_id) {
        loader.value.follow = true
        Drequest.toggleFollow(props.user_id, follow.value)
          .post(response => {
            console.log(response)
            loader.value.follow = false
            emit('following', !follow.value, { nbfollowers: response.nbfollowers })
          })
      } else {
        alert('Vous devez etre connecte pour liker')
        emit('initlogin')
      }
    }
    return {
      loader,
      follow,
      toggleFollow,
      icons: {
        mdiDotsVertical,
        mdiComment,
        mdiShareVariant,
        mdiHeart,
        mdiHeartOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  }
}
</script>

<style scoped>

</style>
