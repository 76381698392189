<template>
  <v-card elevation="0">
    <v-card-title class="pa-0">
      Charger les pages
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card elevation="0">

      <v-card-title>
        <v-btn
          v-if="filestoupload.length > 1" small class="ma-3"
          @click="loaders.sortimage = true">
          <v-icon>{{ icons.mdiArrowUpDown }}</v-icon>
          Modifier l'ordre des images
        </v-btn>
      </v-card-title>
      <v-card-text class="pl-1 pr-1 pa-md-3 pa-lg-6">
      <v-row dense style="overflow-x: hidden"
              v-if="filestoupload.length"
      >
        <template v-for="(item, $index) in filestoupload">

          <chapter-image-form-controller
            :image="item"
            :key="item.oburl"
            :chapter="chapter"
            :index="$index"
            @removeimage="removeimage"
            @uploaded="uploaded"
            @changeCover="(idCover, nchapter) => $emit('changeCover', idCover, nchapter)"
          ></chapter-image-form-controller>
        </template>
      </v-row>
      </v-card-text>
      <v-card-text>
        <v-file-input
          v-model="images"
          label="Ajouter une image"
          dense
          multiple
          accept=".jpeg, .jpg, .png, .gif"
          outlined
          @change="addimages(images)"
        ></v-file-input>
      </v-card-text>

    </v-card>

    <v-dialog v-model="loaders.sortimage">

      <v-card>
        <v-card-text>Déplacer les images de façon verticale pour les réorganiser.</v-card-text>
        <v-list class="mb-3" style="max-height: 350px; overflow: auto"
                v-if="filestoupload.length"
                dense
        >
          <draggable
            v-model="filestoupload"
            handle=".handle"
          >
            <transition-group name="list">
              <template v-for="(item, $index) in filestoupload">
                <v-list-item
                  :key="'img-'+$index"
                  class=" handle bordered"
                  dense
                >
                  <img
                    class="mr-3"
                    width="50"
                    :src="item.oburl"
                  >

                  <v-list-item-content>
                    <v-list-item-title class="title-inline">
                      {{ item.file.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ (item.file.size / 1024 / 1024).toFixed(2) }} Mo
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </transition-group>
          </draggable>
        </v-list>

        <v-card-actions class=" ">
          <v-btn color="secondary" @click="loaders.sortimage = false">Annuler</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loaders.sort" @click="sortlist">Enregistrer cet ordre</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiFolderAlert,
  mdiClose,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAccountGroupOutline,
  mdiChevronLeft,
  mdiArrowUpDown,
  mdiFileImageOutline
} from '@mdi/js'
import {
  computed, ref, onMounted, onUnmounted, watch,
} from '@vue/composition-api'
import draggable from 'vuedraggable'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import PostImageFormController from '@/components/PostImageFormController.vue'
import ChapterImageFormController from "@/components/ChapterImageFormController.vue";

export default {
  components: {
    ChapterImageFormController,
    PostImageFormController,
    draggable,
  },
  props: {
    chapter: {
      type: Object,
      default: () => {
      },
    },
    postedit: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props, {emit}) {
    const $vuetify = getVuetify()
    const user = Drequest.getUser()
    const items = ref([])
    const filestoupload = ref([])
    const files = ref([])
    const images = ref([])
    const nbimageuploaded = ref(0)
    const nbimages = ref(0)
    const e1 = ref(1)

    // const post = ref({})
    const loaders = ref({
      sortimage: false,
      sort: false,
      post: false,
      identify: false,
      postavailable: false,
    })
    const snackbar = ref({})
    const hashtags = ref([])
    const identifies = ref([])

    Drequest.api('lazyloading.scanpage?dfilters=on&per_page=all&next=1&dsort=position&chapter_id:eq=' + props.chapter.id)
      .get(response => {
        console.log(response)

        for (let i = 0; i < response.listEntity.length; i++) {

          filestoupload.value.push({
            ...response.listEntity[i],
            file: response.listEntity[i],
            oburl: (response.listEntity[i]).src,
            toupload: false,
          })
        }

      })
    const addimages = files => {
      console.log(files)
      /* if(!this.post.id){
        alert("Oops une erreur est survenue lors du chargement de vos images. Selectionnez de nouveau svp. si le problème persiste, actualiser la page, votre publication sera conservée.");
        return 0;
      } */
      // files.value = ev.target.files
      loaders.value.postavailable = false
      if (files[0]) {
        for (let i = 0; i < files.length; i++) {
          files[i].toupload = true
          filestoupload.value.push({
            file: files[i],
            oburl: window.URL.createObjectURL(files[i]),
          })
        }
      } else if (files.name) {
        files.toupload = true
        filestoupload.value.push({
          file: files,
          oburl: window.URL.createObjectURL(files),
        })
      }
      if (filestoupload.value.length > 1 && e1.value == 2) {
        e1.value = 3
      }

      console.log(filestoupload.value)
      nbimages.value = filestoupload.value.length
    }

    const uploaded = () => {
      // images.value = []
      nbimageuploaded.value = 0
      for (let item of filestoupload.value)
        if (item.file.id)
          nbimageuploaded.value++

      loaders.value.postavailable = false
      console.log(filestoupload.value, nbimageuploaded.value, nbimages.value)
      if (nbimageuploaded.value === filestoupload.value.length) {
        loaders.value.postavailable = true
        //this.callbackpersistence(this.serverresponse);
        console.log('all images hava well been uploaded thx')
      }
    }
    watch(images, (images, previmages) => {
      // console.log(postcontent, prevPostcontent)
      uploaded()
    })
    const removeimage = (file, index) => {
      filestoupload.value.splice(index, 1)
      nbimages.value--

      if (file.id) {
        nbimageuploaded.value--
        Drequest.api('chapterimage.delete?id=' + file.id)
          .get((response) => {
            console.log(response)
          })
      }
      /* if (nbimageuploaded.value === nbimages.value) {
        loaders.value.postavailable = true
      } */
      uploaded()

    }

    const sortlist = () => {
      loaders.value.sort = true
      var fd = new FormData();
      for(let img of filestoupload.value) {
        fd.append("positions[]", img.id);
      }

      Drequest.api("chapterimage.sort")
        .data(fd)
        .post((response) => {
          console.log(response);
          loaders.value.sort = false
          loaders.value.sortimage = false

        })

    }
    return {
      addimages,
      removeimage,
      sortlist,
      close,
      uploaded,

      hashtags,
      identifies,
      items,
      loaders,
      images,
      nbimages,
      filestoupload,
      e1,

      icons: {
        mdiClose,
        mdiFolderAlert,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFileImageOutline,
        mdiAccountGroupOutline,
        mdiArrowUpDown,
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.todo {
  border-top: 1px solid var(--v-secondary-base);
}

.list-move {
  transition: .3s;
}
</style>
