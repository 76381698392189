<template>
  <v-list dense>
    <template v-for="(item, index) in postcommentll.listEntity">
      <v-divider
        :key="index"
      ></v-divider>

      <v-list-item
        :key="item.id"
      >
        <v-list-item-avatar>
          <v-img
            v-if="item.user.avatar"
            contain
            :src="require('@/assets/images/avatars/'+item.user.avatar)"
          ></v-img>
          <v-img
            v-else
            contain
            :src="require('@/assets/images/avatars/logo.png')"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.user.username }}
            <small class="float-right v-size--x-small">
              <kola-toggle-component
                :subject="'postcomment'"
                :identify="item.id"
                :status="item.liked"
                :user_id="user.id"
                :counter="item.nbkola"
                @liked="(liked, nbkola)=>{item.liked = liked; item.nbkola = nbkola}"
              ></kola-toggle-component>
            </small>
          </v-list-item-title>
          <p class="text-sm" v-html="item.comment"></p>
          <v-list-item-action-text>
            <small class="mr-3 v-size--small">{{ item.date }}</small>
            <v-btn
              x-small
              link
              text
              @click="$emit('reply',{...item, id:parent_id})"
            >- repondre
            </v-btn>
            <v-menu
              v-if="item.user.id == user.id"
              top
              left
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  small
                  style="float: right"
                  v-on="on"
                >
                  <v-icon class="mr-1">
                    {{ icons.mdiDotsHorizontal }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <template>
                  <v-list-item
                    color="error"
                    @click="deleteComment(item, index)"
                  >
                    <v-list-item-title>Supprimer</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-list-item-action-text>

        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { Drequest } from '@/plugins/Drequest'
import { computed, ref } from '@vue/composition-api/dist/vue-composition-api'
import { mdiDotsHorizontal, mdiDotsVertical, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { watch } from '@vue/composition-api'
import KolaToggleComponent from '@/components/KolaToggleComponent'

export default {
  name: 'CommentResponseListComponent',
  components: { KolaToggleComponent },
  props: {
    user: {
      type: Object,
      default: () => {

      },
    },
    nbreply: {
      type: Number,
      default: () => 1,
    },
    newcomment: {
      type: Object,
      default: () => {
      },
    },
    parent_id: Number,
    limit: Number,
  },
  setup(props) {

    const repliedcomment = computed(() => props.newcomment)
    const postcommentll = ref({ listEntity: [] })
    const init = () => {
      Drequest.api(`lazyloading.postcomment?dfilters=on&parent_id:eq=${props.parent_id}`)
        .param({
          next: 1,
          'user_id': props.user.id,
          per_page: props.limit,
        })
        .get(response => {
          console.log(response)
          postcommentll.value = response
        })
    }
    if (props.nbreply > 0) {
      init()
    }

    watch(repliedcomment, (val, old) => {
      console.log(val, old)

      if (val && val.parent_id == props.parent_id) {
        postcommentll.value.listEntity.push(val)
      }

    })

    const deleteComment = (item, index) => {
      if (!confirm('Ce commentaire va etre supprime.\n\n Vous confirmez cette action?')) {
        return 0
      }

      Drequest.api(`delete.postcomment?id=${item.id}`)
        .get(response => {
          console.log(response)
          postcommentll.value.listEntity.splice(index, 1)
        })
    }

    return {
      deleteComment,
      postcommentll,
      icons: {
        mdiDotsHorizontal,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiDotsVertical,
      },
    }
  }
}
</script>

<style scoped>

</style>
