<script setup>

import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import Banner from "@/components/Banner.vue";

export default {
  components: {FollowToggleComponent, Banner},
  props:{
    profile:Object,
    user:Object,
  }
}

</script>

<template>

  <v-card
    class="d-inline-block mr-lg-2 ml-lg-2 mr-sm-1 ml-sm-1 mt-3 text-center"
    :to="'/profile/'+profile.id"
    min-width="120"
  >
    <v-avatar
      size="100"
      class="ma-1 av-active"
    >
      <v-img
        v-if="profile.avatar" contain
        alt="Avatar" aspect-ratio="1"
        :src="require(`@/assets/images/avatars/${profile.avatar}`)"
      />
      <v-img
        v-else  aspect-ratio="1"
        alt="Avatar" contain
        :src="require(`@/assets/images/avatars/logo.png`)"
      />
    </v-avatar>
    <v-card-text class="title-inline ma-1 pa-1 text-center">
      <b>{{ profile.username }}</b>
    </v-card-text>
<!--    <v-card-actions class="justify-center">
      <follow-toggle-component :status="profile.following"
                               :current_user_id="parseInt(user.id)"
                               :user_id="parseInt(profile.id)"
      ></follow-toggle-component>
    </v-card-actions>-->
  </v-card>
</template>

<style scoped lang="scss">

</style>
