<template>
  <div>
    <v-card>
      <v-img v-if="mainpost.id"
        :src="mainpost.postimages[0].uploaddir+'700_'+mainpost.postimages[0].postimage"
        class="align-top mb-3"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
        cover
      >
        <v-card-title class="text-white"  >
          {{ mainpost.title }}
          <v-spacer></v-spacer>
          <v-btn color="primary"
            fab small
            @click="$destroy; $emit('close'); "
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
      </v-img>

      <v-list class="mb-3" style="max-height: 350px; overflow: auto"
              v-if="filestoupload.length"
              dense
      >
        <draggable
          v-model="filestoupload"
          handle=".handle"
        >
          <transition-group name="list">
            <template v-for="(item, $index) in filestoupload">
              <v-list-item
                :key="item.oburl"
                class=" handle bordered"
                dense
              >
                <img
                  class="mr-3"
                  width="50"
                  :src="item.oburl"
                >

                <v-list-item-content>
                  <v-list-item-title class="title-inline">
                    {{ item.file.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ (item.file.size / 1024 / 1024).toFixed(2) }} Mo
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle dense>
                    <v-btn
                      small
                      color="error"
                      @click="removeimage($index)"
                    >
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      class=" "
                    >
                      <v-icon>{{ icons.mdiArrowUpDown }}</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>
            </template>
          </transition-group>
        </draggable>
      </v-list>
      <v-card-text v-if="!post.id">
        <v-file-input
          v-model="images"
          label="Ajouter une image"
          dense
          multiple
          accept=".jpeg, .jpg, .png, .gif"
          outlined
          @change="addimages(images)"
        ></v-file-input>
      </v-card-text>

      <v-card-text  class="pb-0 mb-0">
        <v-textarea
          v-model="postcontent"
          auto-grow
          rows="3"
          outlined
          :prepend-icon="icons.mdiEmoticon"
          clearable
          label="Ecrivez quelque chose ... :D"
        ></v-textarea>
      </v-card-text>

      <v-card-actions >
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="publish"
        >
          Publier
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="loaders.post"
      persistent
      width="300"
    >
      <v-card
        color="secondary"
      >
        <v-card-text>
          Publication en cours ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiClose,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAccountGroupOutline,
  mdiChevronLeft,
  mdiArrowUpDown,
  mdiFileImageOutline
} from '@mdi/js'
import {
  computed, ref, onMounted, onUnmounted, watch,
} from '@vue/composition-api'
import draggable from 'vuedraggable'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'

export default {
  components: {
    draggable,
  },
  props: {
    mainpost: Object,
    postedit: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props, { emit }) {
    const $vuetify = getVuetify()
    const user = Drequest.getUser()
    const items = ref([])
    const filestoupload = ref([])
    const files = ref([])
    const images = ref([])
    const nbimageuploaded = ref(0)
    const nbimages = ref(0)
    const e1 = ref(1)

    // const post = ref({})
    const loaders = ref({
      post: false,
    })
    const snackbar = ref({})
    const postcontent = ref('')

    const post = computed(() => props.postedit)
    watch(post, (post, prevPost) => {
      console.log(post, prevPost)
    })
    const closecomponent = () => {

    }
    const addimage = () => {

    }

    const addimages = files => {
      console.log(files)

      /* if(!this.post.id){
        alert("Oops une erreur est survenue lors du chargement de vos images. Selectionnez de nouveau svp. si le problème persiste, actualiser la page, votre publication sera conservée.");
        return 0;
      } */
      // files.value = ev.target.files
      if (files[0]) {
        for (let i = 0; i < files.length; i++) {
          files[i].toupload = true
          filestoupload.value.push({
            file: files[i],
            oburl: window.URL.createObjectURL(files[i]),
          })
        }
      } else if (files.name) {
        files.toupload = true
        filestoupload.value.push({
          file: files,
          oburl: window.URL.createObjectURL(files),
        })
      }
      if (filestoupload.value.length > 1 && e1.value == 2) {
        e1.value = 3
      }

      console.log(filestoupload.value)
      nbimages.value = filestoupload.value.length
    }

    const removeimage = index => {
      filestoupload.value.splice(index, 1)
      nbimages.value--

      /* Drequest.api('postimage.delete?id=' + file.id).get((response) => {
        // model._apiget('product-image.delete?id=' + file.id, (response) => {
        console.log(response);
        this.filestoupload.splice(index, 1);
      }); */
    }

    const urlify = text => {
      const urlRegex = /(https?:\/\/[^\s]+)/g

      return text.replace(urlRegex, url => `<a href="${url}">${url}</a>`)

      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }

    const publish = () => {

      if (post.value.id) {
        loaders.value.post = true
        Drequest.api(`update.post?id=${post.value.id}`)
          .data({
            post: {
              content: post.value.content,
            },
          })
          .raw(response => {
            console.log(response)
            loaders.value.post = false
            filestoupload.value = []
            postcontent.value = ''
            images.value = []
            nbimages.value = 0
            emit('updated', response.post)
          })
          .fail(e => {
            loaders.value.post = false
            alert('Oops Il y a eu un probleme de connexion essayer de nouveau SVP !')
            console.log(e)
          })

        return 0
      }
      if (!nbimages.value) {
        window.alert('Vous devez inserer au moins une image ou une video.')

        return 0
      }

      const fd = new FormData()
      fd.append('userid', user.id)
      fd.append('parent_id', props.mainpost.id)
      fd.append('content', postcontent.value)
      fd.append('nbimages', nbimages.value)

      let img = {}
      console.log(filestoupload.value)
      for (let i = 0; i < nbimages.value; i++) {
        img = filestoupload.value[i]
        fd.append(`postimage_${i}`, img.file)
      }
      loaders.value.post = true
      Drequest.api('post.persist')
        .data(fd)
        .post(response => {
          console.log(response)
          loaders.value.post = false
          filestoupload.value = []
          postcontent.value = ''
          post.value.title = ''
          images.value = []
          nbimages.value = 0
          emit('posted', response.post)
        })
        .fail(e => {
          loaders.value.post = false
          alert('Oops Il y a eu un probleme de connexion essayer de nouveau SVP !')
          console.log(e)
        })
    }

    return {
      addimages,
      publish,
      removeimage,

      items,
      post,
      loaders,
      postcontent,
      images,
      nbimages,
      filestoupload,
      e1,

      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFileImageOutline,
        mdiAccountGroupOutline,
        mdiArrowUpDown,
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.todo {
  border-top: 1px solid var(--v-secondary-base);
}

.list-move {
  transition: .3s;
}
</style>
